<template>
    <div v-if="detail" class="bg-slightGrey minHeight position_relative" style="padding-bottom: 100px">
        <div class="width-100 paddingTb bg-white margin-bs">
            <div class="width-100 flex-row justify-content-start align-items-center margin-bs">
                <img width="4px" height="24px" src="@/assets/image/icon1.png" alt="" />
                <p class="fs-big fw-mid black margin-l">施工{{ detail.submission_type === '申请' ? '' : detail.submission_type }}申请</p>
            </div>
            <p class="paddingLr90 width-90 fs-mid darkgrey lineHeight">{{ detail.information.submit_unit.name }}</p>
            <p class="paddingLr90 width-90 fs-mid darkgrey lineHeight">{{ submissionStatus }}</p>
            <img v-if="detail.is_vetoed" width="95px" height="75px" src="@/assets/image/pic5.png" class="position_absolute badge" alt="" />
            <img v-else-if="detail.is_complete" width="95px" height="75px" src="@/assets/image/pic4.png" class="position_absolute badge" alt="" />
            <img v-else width="95px" height="75px" src="@/assets/image/pic3.png" class="position_absolute badge" alt="" />
        </div>
        <!-- 审批信息 -->
        <div class="width-90 paddingLr90 br-big van-overflow-hidden">
            <div class="width-90 padding90 bg-white br-big margin-bs">
                <p class="fs-big fw-mid black margin-b">审批信息</p>
                <!-- 如果达到警告标准，修改颜色并添加警告信息和链接 -->
                <p class="fs-mid grey margin-bs">申请单位</p>
                <p class="fs-mid width-100 margin-b" :class="['black', 'orange', 'red'][detail.information.submit_unit.warning_level]">
                    <span>{{ detail.information.submit_unit.name }}</span>
                    <br />
                    <!-- <router-link
                        :to="{ path: '/xc', query: { is_well: false, project__information__submit_unit: detail.information.submit_unit.id } }"
                    > -->
                    <span v-if="detail.information.submit_unit.is_focus"> （此单位有多次施工违规的记录） </span>
                    <!-- <span v-else-if="detail.information.submit_unit.warning_level > 0">
                            （此单位有{{ detail.information.submit_unit.defective_rematch_count }}条异常记录，点击查看）
                        </span> -->
                    <!-- </router-link> -->
                </p>
                <p class="fs-mid width-100 margin-b" :class="['black', 'orange', 'red'][detail.information.submit_unit.warning_level]"></p>
                <p class="fs-mid grey margin-bs">单位地址</p>
                <p class="fs-mid black width-100 margin-b">{{ detail.information.submit_unit.address }}</p>
                <p class="fs-mid grey margin-bs">联系人</p>
                <p class="fs-mid black width-100 margin-b">{{ detail.information.submit_unit.contact }}</p>
                <p class="fs-mid grey margin-bs">联系方式</p>
                <p class="fs-mid black width-100 margin-b">{{ detail.information.submit_unit.contact_phone }}</p>
                <p class="fs-mid grey margin-bs">挖掘原因</p>
                <p class="fs-mid width-100 margin-b" :class="{ blue: content.reason.changed, black: !content.reason.changed }">
                    {{ content.reason.value }}
                </p>
                <p class="fs-mid grey margin-bs">挖掘地段</p>
                <p class="fs-mid width-100 margin-b" :class="{ blue: content.location.changed, black: !content.location.changed }">
                    {{ content.location.value }}
                </p>
                <p class="fs-mid grey margin-bs">挖掘时间</p>
                <p class="fs-mid black width-100 margin-b">
                    <span :class="{ blue: content.start_date.changed }">{{ dateFormat(content.start_date.value) }}</span>
                    至
                    <span :class="{ blue: content.complete_date.changed }">{{ dateFormat(content.complete_date.value) }}</span>
                </p>
                <p v-if="detail.submission_type === '延期'" class="fs-mid width-100 margin-b blue">
                    延期至 {{ dateFormat(detail.postponement.extra_datetime) }}
                </p>
                <p class="fs-mid grey margin-bs">挖掘面积</p>
                <p class="fs-mid width-100 margin-b" :class="{ blue: content.acreage.changed, black: !content.acreage.changed }">
                    {{ content.acreage.value }}
                </p>
                <p class="fs-mid grey margin-bs">路面性质</p>
                <p class="fs-mid width-100 margin-b" :class="{ blue: content.pavement_nature.changed, black: !content.pavement_nature.changed }">
                    {{ content.pavement_nature.value }}
                </p>
                <p v-if="detail.submission_type === '变更'" class="fs-mid grey margin-bs">变更原因</p>
                <p v-if="detail.submission_type === '变更'" class="fs-mid black width-100 margin-b">{{ detail.change.change_reason }}</p>
                <p v-if="detail.submission_type === '延期'" class="fs-mid grey margin-bs">延期原因</p>
                <p v-if="detail.submission_type === '延期'" class="fs-mid black width-100 margin-b">{{ detail.postponement.reason }}</p>
                <p class="fs-mid grey margin-bs">附件</p>
                <div class="flex-row flex-wrap justify-content-start align-items-center margin-ts">
                    <template v-for="(image, index) in detail.annex">
                        <van-image
                            v-if="isImage(image.src)"
                            :key="index"
                            @click="preview(detail.annex, index)"
                            :src="mediaUrl + image.src"
                            width="64"
                            height="64"
                            fit="cover"
                            class="margin-r margin-bs"
                        />
                        <a v-else :key="index" :href="mediaUrl + image.src" :download="fileName(image.src)">
                            <van-image :src="fileCoverNotImage(image.src)" width="64" height="64" fit="cover" class="margin-r margin-bs" />
                        </a>
                    </template>
                    <van-image-preview v-model="previewShow" :images="previewImages" :start-position="previewIndex"> </van-image-preview>
                </div>
            </div>
        </div>
        <!-- 绑定协警 -->
        <div class="width-90 paddingLr90 br-big van-overflow-hidden margin-bs">
            <div class="width-90 padding90 bg-white br-big">
                <div class="flex-row justify-content-spaceBetween align-items-center width-100 margin-b">
                    <p class="fs-big fw-mid black">{{ rematchIsBind ? '巡查片警/协警' : '指派辖区片警/协警' }}</p>
                    <p v-if="!rematchIsBind" class="fs-mid darkgrey" @click="showPopup">换一个 >></p>
                </div>
                <div class="width-100">
                    <div v-if="selectMatcher !== null" class="width-100 flex-row justify-content-spaceBetween align-items-end">
                        <div class="width-90 flex-row justify-content-start align-items-star">
                            <van-image
                                :src="selectMatcher.avatar"
                                fit="cover"
                                width="60px"
                                height="70px"
                                class="margin-r br-reg van-overflow-hidden"
                            ></van-image>
                            <div class="van-overflow-hidden">
                                <div class="flex-row justify-content-start align-items-center margin-bs">
                                    <p class="fs-mid fw-mid black margin-r">{{ selectMatcher.name }}</p>
                                    <van-tag color="#CCE4FF" text-color="#007AFF">{{ selectMatcher.group_name }}</van-tag>
                                </div>
                                <p class="fs-sml darkgrey margin-bs">电话：{{ selectMatcher.phone }}</p>
                                <p class="fs-sml darkgrey">所在辖区：{{ selectMatcher.area }}</p>
                            </div>
                        </div>
                        <div v-if="!rematchIsBind" class="width-10 textAlign-r">
                            <p class="red" @click="selectedMatcherId = null">取消</p>
                            <!-- <img width="20px" height="20px" src="@/assets/image/icon8.png" class="" alt="" /> -->
                        </div>
                    </div>
                    <p v-else class="fs-mid black width-100 margin-b">无</p>
                </div>
            </div>
        </div>
        <!-- 审批流程 -->
        <div v-if="!isLocalSupporter" class="width-90 paddingLr90 br-big van-overflow-hidden margin-bs">
            <div class="width-90 padding90 bg-white br-big">
                <div class="flex-row justify-content-spaceBetween align-items-center width-100 margin-b">
                    <p class="fs-big fw-mid black">审批流程</p>
                    <p v-if="detail.is_important">必须上报到大队审批</p>
                </div>

                <div v-for="(review, index) in reviewers" :key="index" class="width-100 flex-row justify-content-start align-items-star">
                    <!-- 头像 -->
                    <div class="width-25">
                        <div class="flex-row justify-content-start align-items-end margin-l">
                            <div
                                v-if="review.avatar === null"
                                class="avatar br-reg bg-blue fs-big fw-mid flex-row justify-content-center align-items-center textAlign-c white"
                            >
                                {{ review.name !== '' ? review.name : review.group.slice(-2) }}
                            </div>
                            <van-image v-else :src="review.avatar" width="48px" height="48px" class="br-reg van-overflow-hidden"></van-image>
                            <van-icon v-if="review.result === '通过'" class="selectBtn position_relative" color="#4CD964" name="checked" />
                            <van-icon v-if="review.result === '否决'" color="#ff0000" class="selectBtn position_relative" name="clear" />
                            <van-icon v-if="review.result === null" class="selectBtn position_relative" color="#999999" name="checked" />
                        </div>
                        <div class="line" v-if="index !== detail.reviews.length - 1"></div>
                    </div>
                    <div class="width-75 flex-column justify-content-start align-items-star">
                        <div class="width-100 margin-bs flex-row justify-content-spaceBetween align-items-center">
                            <p class="fs-big black">{{ review.group }}</p>
                            <p v-if="review.result !== null" class="fs-sml darkgrey">{{ datetimeFormat(review.review_time, 'YYYY-M-D HH:mm') }}</p>
                        </div>
                        <p v-if="review.result !== null" class="fs-sml darkgrey margin-bs">
                            {{ review.name }}<span class="fs-sml"> ({{ review.result }})</span>
                        </p>
                        <p v-if="review.reason" class="bg-slightGrey width-90 fs-sml darkgrey margin-b paddingLr90 lineHeight">
                            {{ review.reason }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <!-- 审核选择框 -->
        <div v-if="canReviewNow" class="width-90 padding90 bg-white flex-row justify-content-spaceBetween align-items-center van-tabbar--fixed">
            <van-button v-if="!isLocalSupporter" @click="refuseshow = true" type="info" class="margin-r" plain block color="#007AFF">拒绝</van-button>
            <van-button @click="reviewSubmit(true)" block type="info" class="margin-l" native-type="submit">{{
                isLocalSupporter ? '指派' : '同意'
            }}</van-button>
        </div>
        <div v-else class="width-90 padding90 bg-white flex-row justify-content-spaceBetween align-items-center van-tabbar--fixed">
            <van-button color="#999999" disabled block type="info" class="margin-l" native-type="submit">{{
                detail.is_vetoed ? '已驳回' : detail.is_complete ? '已通过' : '审核中'
            }}</van-button>
        </div>

        <!-- 选择协警列表 -->
        <van-popup v-model="selectMatcherPopshow" round position="bottom" class="paddingTb" :style="{ 'min-height': '30%', 'max-height': '80%' }">
            <p class="width-90 paddingLr90 fw-mid fs-mid">选择辖区片警/协警</p>
            <div
                class="width-90 padding90"
                v-for="item in rematcherList"
                :key="item.id"
                @click="
                    selectedMatcherId = item.id
                    selectMatcherPopshow = false
                "
            >
                <div class="width-100 flex-row justify-content-spaceBetween align-items-end">
                    <div class="width-90 flex-row justify-content-start align-items-star">
                        <van-image :src="item.avatar" fit="cover" width="60px" height="70px" class="margin-r br-reg van-overflow-hidden"></van-image>
                        <div class="van-overflow-hidden">
                            <div class="flex-row justify-content-start align-items-center margin-bs">
                                <p class="fs-mid fw-mid black margin-r">{{ item.name }}</p>
                                <van-tag color="#CCE4FF" text-color="#007AFF">{{ item.group_name }}</van-tag>
                            </div>
                            <p class="fs-sml darkgrey margin-bs">电话：{{ item.phone }}</p>
                            <p class="fs-sml darkgrey">所在辖区：{{ item.area }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </van-popup>
        <van-popup v-model="refuseshow" class="paddingTb br-big width-70">
            <div class="width-100 flex-row justify-content-start align-items-center margin-b">
                <img width="4px" height="20px" class="" src="@/assets/image/icon1.png" alt="" />
                <p class="fs-big black margin-l">请输入拒绝原因</p>
            </div>
            <div class="paddingLr90 width-90 margin-b">
                <van-cell-group class="border">
                    <van-field v-model="value" label="" placeholder="请输入拒绝原因" />
                </van-cell-group>
            </div>
            <div class="width-90 paddingLr90 bg-white flex-row justify-content-spaceBetween align-items-center">
                <van-button @click="showToast('取消')" size="small" type="info" class="margin-r margin-l margin-ts" plain block color="#007AFF"
                    >取消</van-button
                >
                <van-button
                    @click="reviewSubmit(false, value)"
                    size="small"
                    block
                    type="info"
                    class="margin-r margin-l margin-ts"
                    native-type="submit"
                    >确认</van-button
                >
            </div>
        </van-popup>
    </div>
</template>
<script>
import axios from 'axios'
export default {
    name: '',
    components: {},
    data() {
        // var _this = this;
        return {
            id: 0,
            userInfo: null,

            rematchIsBind: false,

            value: '',
            refuseshow: false,
            index: 0,
            images: [],
            coverNotImage: [['doc', require('@/assets/image/office-word-icon.jpg')]],
            defaultCover: require('@/assets/image/default-file-icon.gif'),

            reviewers: [],
            nowReviewers: [],
            detail: null,

            previewShow: false,
            previewImages: [],
            previewIndex: 0,

            rematcherList: [],
            selectedMatcherId: null,
            selectMatcherPopshow: false
        }
    },
    computed: {
        submissionStatus() {
            if (!this.detail.is_complete) {
                return '审批中'
            } else if (this.detail.is_vetoed) {
                return '已驳回'
            } else {
                return '已通过'
            }
        },
        selectMatcher() {
            if (this.rematchIsBind) {
                return this.detail.rematch_user
            }
            if (this.selectedMatcherId !== null) {
                return this.rematcherList.find((matcher) => matcher.id === this.selectedMatcherId)
            }
            return null
        },
        canReviewNow() {
            if (this.detail.is_complete) {
                return false
            }
            const { dd_user_id } = this.userInfo
            return this.nowReviewers.indexOf(dd_user_id) !== -1
        },
        content() {
            let o = {}
            if (this.detail.submission_type === '申请') {
                for (const field in this.detail.application) {
                    if (field !== 'submit_unit') {
                        o[field] = { value: this.detail.application[field], changed: null }
                    }
                }
            } else if (this.detail.submission_type === '变更') {
                for (const field in this.detail.change) {
                    if (field === 'change_reason') {
                        continue
                    }
                    const changedValue = this.detail.change[field]
                    const changed = changedValue !== null && changedValue !== ''
                    o[field] = { value: changed ? changedValue : this.detail.information[field], changed: changed }
                }
            } else if (this.detail.submission_type === '延期') {
                for (const field in this.detail.information) {
                    if (['submit_unit', 'area', 'id'].indexOf(field) === -1) {
                        o[field] = { value: this.detail.information[field], changed: null }
                    }
                }
            } else {
                console.error('存在无法识别的上报类型')
            }
            return o
        },
        isLocalSupporter() {
            return !this.userInfo.is_global && this.userInfo.is_supporter
        }
    },
    created() {
        this.$store.state.tabbarIndex = this.TAB_REVIEW
        const { id } = this.$route.query
        this.id = parseInt(id)
        this.userInfo = JSON.parse(sessionStorage.getItem('userInfo'))
        const { access_token } = this.userInfo
        axios
            .get(`/api/supervision/submission_detail/${id}/`)
            .then((res) => {
                this.detail = res.data
                if (this.detail.rematch_user !== null) {
                    this.rematchIsBind = true
                    this.detail.rematch_user.avatar = this.avatarUrl(access_token, this.detail.rematch_user.avatar_media_id)
                }

                return axios.get(`/api/supervision/submission_detail/${id}/review_route/`)
            })
            // 获取可用的巡查负责人
            .then((res) => {
                this.reviewers = res.data.routes
                this.nowReviewers = res.data.now_reviewers
                // 初始化头像
                if (process.env.NODE_ENV === 'production') {
                    for (let matcher of this.reviewers) {
                        matcher.avatar = this.avatarUrl(access_token, matcher.avatar_media_id)
                    }
                } else {
                    for (let matcher of this.reviewers) {
                        matcher.avatar = null
                    }
                }
                return axios.get(`/api/supervision/submission_detail/${id}/rematch_candidate/`)
            })
            .then((res) => {
                this.rematcherList = res.data
                if (process.env.NODE_ENV === 'production') {
                    for (let matcher of this.rematcherList) {
                        matcher.avatar = this.avatarUrl(access_token, matcher.avatar_media_id)
                    }
                } else {
                    for (let matcher of this.rematcherList) {
                        matcher.avatar = null
                    }
                }
            })
            .catch(() => {
                this.$dialog.alert({
                    message: '无法获取数据，请检查网络后重新打开。'
                })
            })
    },
    mounted() {},
    methods: {
        showToast(e) {
            this.$toast(e)
            this.refuseshow = false
        },
        preview(images, previewIndex) {
            this.previewShow = true
            this.previewImages = images.map((image) => this.mediaUrl + image.src)
            this.previewIndex = previewIndex
        },
        showPopup() {
            this.selectMatcherPopshow = true
        },
        reviewSubmit(pass, reason) {
            const { is_global, is_supporter } = JSON.parse(sessionStorage.getItem('userInfo'))
            if (pass && is_supporter && !is_global && this.selectedMatcherId === null && !this.rematchIsBind) {
                this.showToast('请指定巡查')
            } else {
                let data = {
                    result: pass,
                    submission: this.id,
                    rematch_dd_user: null,
                    reason_of_result: ''
                }
                if (pass) {
                    if (!this.rematchIsBind) {
                        data.rematch_dd_user = this.selectedMatcherId
                    }
                } else {
                    if (reason === '') {
                        this.showToast('必须填写拒绝理由')
                        return
                    }
                    data.reason_of_result = reason
                }
                const toast = this.$toast.loading('提交中')
                axios
                    .post('/api/supervision/submit_review/', data)
                    .then((res) => {
                        if (res.data.code === 0) {
                            this.showToast(pass ? '已同意' : '已驳回')
                            this.$router.back()
                            // console.log('success')
                        } else {
                            this.showToast('请求失败，请检查网络并重新登录')
                        }
                    })
                    .catch(() => {
                        this.$dialog.alert({
                            message: '请求失败，请检查网络并重新登录'
                        })
                    })
                    .finally(() => {
                        toast.clear()
                    })
            }
        },
        isImage(src) {
            return ['.jpg', '.png', '.gif', '.bmp'].some((t) => src.endsWith(t))
        },
        fileName(src) {
            const splits = src.split('/')
            return splits[splits.length - 1]
        },
        fileCoverNotImage(src) {
            const filename = this.fileName(src)
            const filenameList = filename.split('.')
            if (filenameList.length < 2) {
                return this.defaultCover
            }
            const extraName = filenameList[filenameList.length - 1]
            for (const icon of this.coverNotImage) {
                const [extra, image] = icon
                if (extraName.startsWith(extra)) {
                    return image
                }
            }
            return this.defaultCover
        }
    }
}
</script>

<style lang="less" scoped>
.badge {
    top: 70px;
    right: 5%;
}
.avatar {
    width: 48px;
    height: 48px;
}
.line {
    height: 40px;
    width: 2px;
    background: #eeeeee;
    margin-left: 33px;
}
.selectBtn {
    left: -10px;
    border: 2px solid #ffffff;
    border-radius: 10px;
    background: #ffffff;
}
.van-cell {
    padding: 5px 16px !important;
}
</style>
